import React, {useEffect, useRef} from "react"
import Single from "../../single"
import info1 from "../../../../static/img/irstrat/video-difusion.png";
import noticias10 from "../../../../static/img/blog/noticias-10-.png";

const Detail = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            window.jquery_scripts()
        }
        return () => {
            isMountedComponent.current = false
        }
    })

    return (
        <Single location={props.location} title='Diversidad en las juntas directivas'>
            <div className="main_blog_items">
                <div className="main_blog_item">
                    <div className="main_blog_image img_hover">
                        <img alt={" "} src={noticias10}/>
                    </div>
                    <div className="main_blog_text">
                        <div className="post_info">
                            <a href="#" className="date">
                                20 Nov. 2020
                            </a>
                            <div className="blog_author_area">
                 <span>
                  Por : Ricardo Ávila
                </span>
                                <span>
                    Comunicación
                </span>
                            </div>
                        </div>

                        <h2>Diversidad en las juntas directivas</h2>
                        <br/>
                    </div>
                </div>
                <div className="s_main_text">
                    <p>A lo largo de los últimos años, la diversidad en las juntas directivas de las compañías (en
                        materia de género, experiencia,
                        áreas de <em>expertise</em>, etc.) se ha convertido en un área de enfoque para los
                        inversionistas. Las recientes protestas públicas
                        por la equidad racial e inclusión genuina en conjunto con la presión de empleados, inversores y
                        clientes han impulsado en gran medida
                        la consideración de la diversidad racial y étnica como un punto base en la conformación de las
                        juntas de consejo. </p>

                    <p>De acuerdo con Forbes, entre las compañías que conforman el índice S&P 500 ya no existen consejos
                        que sean exclusivamente
                        masculinos. Para 2019, las mujeres constituían casi la mitad de las posiciones directivas de
                        dichas compañías, de las cuales, el
                        10% eran mujeres de color. Pese a existir un gran avance respecto a la diversidad del consejo,
                        es evidente que estos progresos han sido
                        demasiado lentos para satisfacer las crecientes expectativas de todas las partes
                        interesadas. </p>

                    <p>Según estudios sobre el rendimiento de las empresas, se ha demostrado que la pluralidad en los
                        consejos de administración
                        tiene correlación con un mayor rendimiento financiero e impacta en su capacidad para atraer y
                        retener talento. En este respecto,
                        debido a que una representación diversa a nivel ejecutivo conduce a un aumento en la variedad de
                        pensamientos y a una mejor toma de
                        decisiones, la conformación de este tipo de consejos “plurales” no debe significar una
                        obligación, sino una oportunidad.</p>

                    <p>Para lograr juntas directivas diversificadas es importante comprender el valor que agrega a la
                        empresa, es decir, entenderlo
                        como un catalizador del crecimiento y la capacidad de análisis y gestión. Asimismo, es relevante
                        contar con un proceso sistemático
                        y riguroso de contratación que asegure que los miembros del consejo sean más tolerantes a estas
                        nuevas ideas y puntos de vista, para
                        poder maximizar el aprovechamiento de los beneficios inherentes a esta práctica.</p>

                    <p>El camino para lograr un consejo heterogéneo puede llegar a ser complicado e incierto, por ello,
                        obtener una experiencia
                        profunda y una amplia red de contactos es el primer paso para que los diferentes líderes puedan
                        unirse a los consejos de
                        administración. De acuerdo con Forbes, 26% de los miembros del consejo son reclutados por su
                        experiencia especifica en una industria
                        o sector, 13% por sus conocimientos financieros y 10% por su experiencia ejecutiva pasada o
                        actual. Aunque es importante considerar
                        la experiencia en la industria, reclutar personas con conocimiento de los clientes y de los
                        aspectos ESG (<em>Environmental,
                            Social and Governance</em>) ayudará a conformar un consejo más balanceada. </p>

                    <p>La pluralidad de los consejos de administración no es solo un imperativo social; al transmitir
                        una percepción de inclusión,
                        también, tiene un impacto en la marca y reputación de la empresa. En la medida que cada vez más
                        consejos se conformen de una amplia
                        gama de competencias multidisciplinarias, prioridades y conocimientos provenientes de múltiples
                        miembros, estos podrán tomar decisiones
                        más acertadas, al contar con percepciones, desde ángulos diferentes.</p>

                    <p>Referencias Bibliográficas
                        <ul>
                            <li>Orowitz, H. (2020). Board Diversity 2020-2021: How Investor Focus is Evolving. Octubre
                                12, 2020. <a
                                    href="https://www.georgeson.com/us/board-diversity-2020-2021-how-investor-focus-is-evolving"
                                    target="_blank"> Georgeson</a>.
                            </li>
                            <li>Ellingrud, K. (2020). Accelerating Board Diversity. Junio 26, 2020. <a
                                href="https://www.forbes.com/sites/kweilinellingrud/2020/06/26/accelerating-board-diversity/?sh=7698fe45106c"
                                target="_blank"> Forbes</a>.
                            </li>
                            <li>Afghan, F. (2019). The importance of diversity in the boardroom. Agosto 23, 2019. <a
                                href="https://www.simplifie.com/boards-blog/the-importance-of-diversity-in-the-boardroom"
                                target="_blank"> Simplifie</a>.
                            </li>
                        </ul>
                    </p>

                </div>
            </div>
        </Single>
    )
}

export default Detail
